import React from 'react'

import { map } from  "lodash"

import {
  IonItem,
  IonList,
  IonIcon,
  IonLabel
} from "@ionic/react"

import { star } from 'ionicons/icons'
import { squareOutline, checkboxOutline } from 'ionicons/icons'


class MeasuresDisplay extends React.Component {


  renderMeasureOutput = (measure) => {
    if(this.value(measure)) {
      const name = `${measure.measure_type}Output`
      return <IonItem key={measure.id}>
      <IonLabel color='medium'>{measure.name}</IonLabel>
      {this[name](measure)}
      </IonItem>
    }
  }

  checkboxOutput = (measure) => {
    const icon = this.value(measure) === 1 ? squareOutline : checkboxOutline;
    return <IonIcon icon={icon} size="large" />
  }

  starsOutput = (measure) => {
    return  <div className='stars_measure'>
        {map([1,2,3,4,5],(val) => this.renderStar(val, measure)  )}
      </div>
  }

  renderStar = (idx, measure) => {
    var val = this.value(measure)
    const ic  = (val >= idx)

    if(ic) { 
      return  <IonIcon key={idx} icon={star} />
     }
  }

  numberOutput = (measure) => {
    return this.value(measure)
  }

  textOutput = (measure) => {
    return this.value(measure)
  }


  value = (measure) => {
    return (this.props.values || {})[measure.id] || ''
  }


  render() {
    return <IonList>
    {map(this.props.measures, this.renderMeasureOutput)}
    </IonList>
  }
}


export default MeasuresDisplay