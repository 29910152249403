

import { 
  UPDATE_LOGIN,
  SUBMIT_LOGIN,
  LOGIN,
  RESET_TOKEN,
  SYNC_JOURNALS,
  UPDATE_JOURNAL,
  SYNC_MEASURES,
  EDIT_MEASURES,
  ADD_MEASURE,
  UPDATE_MEASURE,
  REORDER_MEASURES,
  ENTER_MEASURE,
  DELETE_MEASURE,
  IGNORE_JOURNAL,
  UPDATE_NOTIFICATIONS
} from './actions'


import { Journal } from "./declarations"


import { map, last, filter, each } from "lodash"

let _allreducers:{ [action: string] : Function[] } = {};

function syncJournals(index:any, journalDetails:Journal[]) {
  index = {...index }

  let journals: number[] = [];

  journalDetails.forEach((journal:Journal) => {
    journals.push(journal.id)

    // Save any changes to the journal if they are 
    // unsynced
    if(index[journal.id] && index[journal.id].dirty) {
      journal.dirty = true
      journal.body = index[journal.id].body
    }
    journal = { ...index[journal.id], ...journal}
    index[journal.id] = journal
  })

  return {index, journals}
}

add(UPDATE_LOGIN, (state:any, action:any) => {
  const login = { ...state.login }
  login[action.field] = action.value;
  return { ...state, login }  
})

add(SUBMIT_LOGIN, (state:any, action:any) => {
  const login = { ...state.login, submitting: action.submitting, error: action.error, register_error: action.register_error }
  return { ...state, login }  
})

add(LOGIN, (state:any, action:any) => {
  const login = { ...state.login, submitting: false }
  const { token, username, account } = action
  return { ...state, login, loggedIn: true, token, username, account }  
})

add(RESET_TOKEN, (state:any, action:any) => {
  return { ...state, token: null, username: null, loggedIn: false };
})

add(SYNC_JOURNALS, (state:any, action:any) => {
  return { ...state, ...syncJournals(state.index, action.journals) }
})

add(UPDATE_JOURNAL, (state:any, action:any) => {
  let index = {...state.index }

  const { attr, journalId } = action

  const journal = index[journalId]

  if(action.load && journal.dirty) {
    delete attr['body']
    delete attr['measures']
  }

  let children: any[] = index[journalId].children;
  if(attr.children) {
    const synced = syncJournals(index,attr.children)
    index = synced.index;
    children = synced.journals;
  } 

  let references: any[] = index[journalId].references;
  if(attr.references) {
    const syncedRef = syncJournals(index,attr.references)
    index = syncedRef.index;
    references = syncedRef.journals;
  }   

 index[journalId] = { ...journal, ...attr, children, references  }

 return {...state, index: index }
})

add(IGNORE_JOURNAL, (state:any, action:any) => {
  let journals = filter(state.journals, (journalId) => action.journalId !== journalId)

  let index = { ...state.index }
  delete index[action.journalId]

  return { ...state, journals, index }
})


add(SYNC_MEASURES, (state:any, action:any) => {
  return {...state, activeMeasures: action.measures }
})


var nextMeasureId = 1;

function newMeasure(state:any) {
  const lastMeasure = last(filter(state.measures, (measure) => !measure._delete ))
  const lastPosition = lastMeasure ? lastMeasure.position : 0

  const measure = {
    id: `measure${nextMeasureId}`,
    name: "",
    measure_type: "checkbox",
    measure_display: "total",
    position: lastPosition + 1
  }
  nextMeasureId +=1;

  return measure
}

add(EDIT_MEASURES, (state:any, action:any) => {
  var measures = [ ...state.activeMeasures ] || []

  if(measures.length === 0) {
    measures = [ newMeasure(state)]
  }

  return {...state, measures }
})



add(ADD_MEASURE, (state:any, action:any) => {
  var measures = [ ...state.measures, newMeasure(state) ]

  return {...state, measures }
})

add(UPDATE_MEASURE, (state:any, action:any) => {

  var measures = map(state.measures, (measure) => {
    if(measure.id === action.id) {
      const copy = { ...measure }
      copy[action.prop] = action.value
      return copy
    } else {
      return measure
    }
  })

  return {...state, measures }
})

add(REORDER_MEASURES, (state:any, action:any) => {
  // create a deep copy
  var measures = map(state.measures, (measure) => { return { ...measure }})

  const moved = measures[action.from]
  // new array w/o the from 
  measures = filter(measures, (m) => m.id !== moved.id)

  measures.splice(action.to,0, moved)

  // leave the deleted measures alone and don't count in position
  measures = map(measures, (measure,idx) => { 
    if(measure._delete) {
      return measure;
    } else {
      idx += 1
      return { ...measure, position: idx }
    }
  })
  return {...state, measures}
})


add(DELETE_MEASURE, (state:any, action:any) => {
  var measures = map(state.measures, (measure) => {
    if(measure.id === action.measureId) {
      return { ...measure, _delete: true, idx: 10000 }
    } else {
      return measure
    }
  })

  return {...state, measures}
})

add(ENTER_MEASURE, (state:any, action:any) => {
  var index = {...state.index }

  var journalId = parseInt(action.journalId,10)

  const oldJournal = index[journalId] || { id: journalId }
  var journal = { ...oldJournal }

  var measures = { ...journal.measures } || {}

  measures[action.measureId] = action.value
  journal['measures'] = measures
  journal['dirty'] = true
  index[journalId] = journal
  return { ...state, index }
})


add(UPDATE_NOTIFICATIONS, (state:any, action:any) => {
  const notifications = {...state.notifications, on: action.on }
  return { ...state, notifications }
})


function add(name:string, reducer:Function) {
  _allreducers[name] = _allreducers[name] || []
  _allreducers[name].push(reducer)
}

function run(state:any,action:any) {
  state = state || {}
  if(!_allreducers[action.type]) return state;
  each(_allreducers[action.type],(reducer) => {
    state = reducer(state, action)
  })
  return state;
}


export default run;