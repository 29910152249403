import React from 'react'

import MeasureInputs from "../components/MeasureInputs"

import { trash, add } from 'ionicons/icons';


import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonButtons,
   IonButton,
   IonFab,
   IonFabButton,
   IonIcon,
   IonReorder,
   IonReorderGroup,
   IonList,
   IonListHeader,
   IonLabel,
   IonSelect,
   IonSelectOption,
   IonItem,
   IonItemGroup,
   IonInput,
   IonText,
   IonMenuButton
 } from '@ionic/react';


import { map } from  "lodash"

import { Measure } from "../declarations"


interface Props {
  measures: Measure[];
  sampleMeasures: any[];

  saveMeasures: Function;
  addMeasure: Function;
  editMeasures: Function;
  updateMeasure: Function;
  deleteMeasure: Function;
  reorderMeasures: Function;
  enterMeasure: Function;
}


class MeasuresEdit extends React.Component<Props, any>  {



  componentDidMount() {
    setTimeout(() => { 
      this.props.editMeasures()
    },10)
  }

  renderMeasureField = (measure:Measure) => {

    var { updateMeasure } = this.props; 
    return (//<IonReorder key={measure.id}>
      <IonItemGroup key={measure.id} className="measure-edit">
        <IonItem>
          <IonLabel position="stacked">Measure Name<IonText color="danger">*</IonText></IonLabel>

          <IonInput
           placeholder="Name (or Emoji)"

           value={measure.name||""} 
           onIonChange={(e:any) => { updateMeasure(measure.id, "name", e.currentTarget.value) } }
           />
           <IonReorder slot="end"></IonReorder>
        </IonItem>
        <IonItem>
          <IonLabel>Type</IonLabel>
          <IonSelect interface="action-sheet"
            value={measure.measure_type || "checkbox"}
            onIonChange={(e:any) => { updateMeasure(measure.id, "measure_type", e.currentTarget.value) } }
          >
            <IonSelectOption value="checkbox">Checkbox</IonSelectOption>
            <IonSelectOption value="stars">Stars</IonSelectOption>
            <IonSelectOption value="number">Number</IonSelectOption>
            <IonSelectOption value="text">Text</IonSelectOption>
          </IonSelect>
         </IonItem>
         <IonItem>
           <IonLabel>Show</IonLabel>
           <IonSelect interface="action-sheet"
              value={measure.display_type || "total"}
              onIonChange={(e:any) => { updateMeasure(measure.id, "display_type", e.currentTarget.value) } }
            >
              <IonSelectOption value="total">Total</IonSelectOption>
              <IonSelectOption value="average">Average</IonSelectOption>
              <IonSelectOption value="percent">Percent</IonSelectOption>
           </IonSelect>
        </IonItem>
        <IonItem>
          <IonButton onClick={(e:any) =>  { this.props.deleteMeasure(measure.id) } }><IonIcon icon={trash as any} />remove</IonButton>
        </IonItem>
      </IonItemGroup>
     //</IonReorder>
     );
  }

  sorted = (e:any) => {
    this.props.reorderMeasures(e.detail.from, e.detail.to)
    e.detail.complete()
  }

  renderSampleForm() {
    if(this.props.measures.length > 0) {
      return <MeasureInputs measures={this.props.measures} values={this.props.sampleMeasures} onChange={this.props.enterMeasure} />
    }
  }

  render() {
    const { saveMeasures, measures, addMeasure } = this.props;

    return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => saveMeasures()}>Save</IonButton>
          </IonButtons>
          <IonTitle>Edit Measures</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <IonIcon icon={add as any} onClick={ (e:any) => { addMeasure() }} />
        </IonFabButton>
      </IonFab>
      <IonContent>
      <IonList>
        <IonReorderGroup onIonItemReorder={this.sorted} disabled={false}>
          {map(measures, this.renderMeasureField)}
        </IonReorderGroup>
      </IonList>
      <IonList>
        <IonItem><IonText>Measures allow you to track additional information each day.</IonText></IonItem>
        <IonListHeader>
          <IonLabel>Sample Inputs</IonLabel>
        </IonListHeader>
       </IonList>
       {this.renderSampleForm()}
      </IonContent>
    </IonPage>
  }
}


export default MeasuresEdit