import SumDayApi from "./sum_day_api.js"


import Notifications from "./notifications"

declare function setTokenSentToServer(sent: Boolean) : void;
declare function sendTokenToServer(refreshedToken: String) : void;
declare function showToken(msg:String, err:Object) : void;


export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export const LOGIN        = "LOGIN";
export const RESET_TOKEN  = "RESET_TOKEN";
export const SYNC_JOURNALS = "SYNC_JOURNALS";
export const UPDATE_JOURNAL = "UPDATE_JOURNAL"
export const IGNORE_JOURNAL = "IGNORE_JOURNAL"


export const SYNC_MEASURES = "SYNC_MEASURES"
export const EDIT_MEASURES = "EDIT_MEASURES"
export const ADD_MEASURE = "ADD_MEASURE"
export const UPDATE_MEASURE = "UPDATE_MEASURE"
export const REORDER_MEASURES = "REORDER_MEASURES"
export const SAVE_MEASURES = "SAVE_MEASURES"
export const ENTER_MEASURE = "ENTER_MEASURE"
export const DELETE_MEASURE= "DELETE_MEASURE"

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"

export function extractJournalId(props:any) {
  return parseInt(props.match.params.journalId,10)
}


export function updateLogin(field:String, value:any) {
  return {
    type: UPDATE_LOGIN,
    field,
    value
  };
}

function storeLogin(dispatch:Function, result:any) {
  window.localStorage['username'] = result.username
  window.localStorage['token'] = result.token

  dispatch({ type: LOGIN, token: result.token, username: result.username, account: result })
}

function clearLogin() {
  window.localStorage['username'] = null
  window.localStorage['token'] = null
}

export function submitLogin() {
  return function(dispatch:Function,getState:Function) {
    var login = getState().sumday.login
    let device = getState().sumday.device; 

    dispatch({ type: SUBMIT_LOGIN, submitting: true })

    SumDayApi.login(login.username, device, login.password).then((result) => {
      if(result.error) { 
        dispatch({ type: SUBMIT_LOGIN, submitting: false, error: result.error })
      } else {
        storeLogin(dispatch, result)
        dispatch(syncMeasures())
        //dispatch(navigateTo("/", true))
      }
    })
  }
}


export function submitRegistration() {

  return function(dispatch:Function,getState:Function) {
    var login = getState().sumday.login
    //check tos and password == password confirmation
    var error:string = "";
    if(!login.username) {
      error = "Please enter an email";
    }
    if(!login.password) {
      error =  "Please enter a password";
      return
    }
    if(login.password !== login.password_confirmation) {
      error =  "Password does not match confirmation";
    }
    if(!login.tos) {
      error = "Please accept Terms of Use"
    }

    if(error !== "") {
      dispatch({ type: SUBMIT_LOGIN, register_error: error})
      return Promise.reject(error);
    }

    login = getState().sumday.login
    dispatch({ type: SUBMIT_LOGIN, submitting: true })

    return SumDayApi.register(login).then((result) => {
      if(result.error) { 
        dispatch({ type: SUBMIT_LOGIN, submitting: false, register_error: result.error })
        return Promise.reject(result.error)
      } else {
        storeLogin(dispatch, result)
        dispatch(syncMeasures())
        return Promise.resolve()
      }
    })
  }
}


export function verifyLogin() {
  return function(dispatch:Function, getState:Function) {

    let token = getState().sumday.token; 
    let device = getState().sumday.device; 
    if(!token || token === "null") { return }

    SumDayApi.verify(token, device).then((result) => {
      if(result.error) {
        dispatch({ type: RESET_TOKEN })
        dispatch({ type: SUBMIT_LOGIN, submitting: false, error: result.error })
      } else {
                console.log(result)

        storeLogin(dispatch, result)
        dispatch(syncMeasures())
        if(getState().sumday.notifications.on) {
          syncMessagingToken(dispatch, getState)
        }
      }
    });
  }  
}


export function logout() {
  return function(dispatch:Function, getState:Function) {
    clearLogin()
    dispatch({ type: RESET_TOKEN })
    //dispatch(navigateTo(`/login`,true))
  }
}

export function syncMeasures() {
  return function(dispatch:Function, getState:Function) { 
    SumDayApi.getMeasures().then((measures) => {
      dispatch({ type: SYNC_MEASURES, measures: measures.measures })
    })
  }
}

export function editMeasures() {
  return { type: EDIT_MEASURES }
}


export function addMeasure() {
  return { type: ADD_MEASURE }
}

export function saveMeasures() {
  return function(dispatch:Function, getState:Function) {
    SumDayApi.saveMeasures(getState().sumday.measures).then((measures) => {
      dispatch({ type: SYNC_MEASURES, measures: measures.measures })
    })
  }
}

export function enterMeasure(journalId:number, measureId:number, value:any) {
  return function(dispatch:Function, getState:Function) { 
     dispatch({ type: ENTER_MEASURE,
              journalId: journalId, 
              measureId: measureId, 
              value: value 
            })
   }
}

export function deleteMeasure(id:number) {
  return { type: DELETE_MEASURE,
             measureId: id 
           }
}

export function reorderMeasures(from:number,to:number) {
  return { type: REORDER_MEASURES, from, to}

}

export function updateMeasure(id:number, prop:String, value:any) {
  return { type: UPDATE_MEASURE, id, prop, value }
}


export function viewJournal(journalId:number) {
  return function(dispatch:Function, getState:Function) {
    //dispatch(navigateTo(`/journal/${journalId}`))
  }
}


export function loadJournals() {
  return function(dispatch:Function, getState:Function) {
    return SumDayApi.journals().then((json) => {
      dispatch({ type: SYNC_JOURNALS, journals: json.journals })
    });
  }
}

export function loadJournal(jid:number) {
  return function(dispatch:Function, getState:Function) {
    return SumDayApi.journal(jid).then((json) => {
      dispatch({ type: UPDATE_JOURNAL, journalId: jid, attr:json, load: true })
    });
  }
}


export function ignoreJournal(jid:number) {
  return function(dispatch:Function, getState:Function) {
    SumDayApi.ignoreJournal(jid).then((json) => {
      dispatch({ type: IGNORE_JOURNAL, journalId: jid })
    });
  } 
}

export function updateJournal(jid:number, attr:Object) {
  return {
    type: UPDATE_JOURNAL,
    journalId: jid,
    attr: attr
  }
}


export function addJournalImage(journalId:number, files:any) {  
  return function(dispatch:Function, getState:Function) {
    const types = ['image/png', 'image/jpeg', 'image/gif']  

    let errs: any[] = []  
    let file = files[0] 

    if (types.every(type => file.type !== type)) { 
      errs.push(`'${file.type}' is not a supported format`) 
    } 

    if (file.size > 15000000) { 
      errs.push(`'${file.name}' is too large, please pick a smaller file`)  
    } 

    if (errs.length) { 
      // Show an alert w/ f7
      // errs.forEach(err => this.toast(err, 'custom', 2000, toastColor)) 
    } else {  
      let reader = new FileReader() 
      reader.onload = (e) => {  
        dispatch(updateJournal(journalId, { image: file, image_url: { medium_url:  e!.target!.result }, dirty: true } ))
      } 
      reader.readAsDataURL(file)  
    } 
  }
}


export function saveJournal(journalId:number) {
  return function(dispatch:Function, getState:Function) {
    const journal = getState().sumday.index[journalId]
    return SumDayApi.saveJournal(journal).then((json) => {
      dispatch({ type: UPDATE_JOURNAL, 
                  journalId: journal.id,
                  attr: { ...json, dirty: false }
                })
      dispatch(loadJournals())
    })
  }
}


export function updateNotifications(on:boolean) {
  return function(dispatch:Function, getState:Function) { 
    const device = getState().sumday.device;
    if(on) { 
      Notifications.register().then((token) => {
        SumDayApi.syncNotificationToken(token, device).then((json) => {
          localStorage['notificationsOn'] = "on"
          console.log(token)
          dispatch({ type: UPDATE_NOTIFICATIONS, on: true, device_token: token }) 
         });
      }).catch( (err) => {
        SumDayApi.syncNotificationToken(false, device)
        localStorage['notificationsOn'] = "off"
        //dispatch({ type: SHOW_TOAST, message: err, color: "error"})
      })
    } else {
      SumDayApi.syncNotificationToken(false, device)
      dispatch({ type: UPDATE_NOTIFICATIONS, on: false })
      localStorage['notificationsOn'] = "off"
    }
  }
}

function syncMessagingToken(dispatch:Function, getState:Function) {
  Notifications.onRefresh((refreshedToken) => {
     console.log('Token refreshed.');
      // Indicate that the new Instance ID token has not yet been sent to the
      // app server.
      setTokenSentToServer(false);
      // Send Instance ID token to app server.
      sendTokenToServer(refreshedToken);
  },(err) => {
    showToken('Unable to retrieve refreshed token ',err)
  });

}


