import React, { useEffect }  from 'react'


import JournalListContainer from "../components/JournalListContainer"
import JournalEntryView from "../components/JournalEntryView"

import JournalDisplay from "../helpers/journal_display"

import { chevronBack } from 'ionicons/icons';



import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonButtons,
   IonButton,
   IonIcon
 } from '@ionic/react';


import { Journal } from "../declarations"

interface Props {
  journalId:number;
  journal:Journal;

  measures: any[];

  history:any;

  loadJournal: Function;
  editJournal: Function;
}

function JournalView(props:Props) {
  const { journal, journalId, loadJournal, measures } = props;

  useEffect(() => {
     loadJournal(journalId)
  }, [journalId, loadJournal])

  if(!journal) { return <IonPage></IonPage> }
  const display = new JournalDisplay(journal)

  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonButton onClick={() => props.history.goBack() }><IonIcon icon={chevronBack}/></IonButton>

          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => props.editJournal()}>Edit</IonButton>
          </IonButtons>
          <IonTitle>{display.navbarTitle()}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <JournalEntryView journal={journal} measures={measures} />
        <JournalListContainer journals={journal.children} history={props.history} noEdit={true} />
      </IonContent>
     </IonPage>
}

export default JournalView;