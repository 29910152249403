import React from 'react'

import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonToast,
   IonInput, 
   IonItem, 
   IonLabel,
   IonList
 } from '@ionic/react';


class LoginPage extends React.Component {

  changeUsername = (e) => {
    this.props.updateUsername(e.target.value)
  }

  clearUsername = () => { this.props.updateUsername("")  }

  changePassword = (e) => {
    this.props.updatePassword(e.target.value)
  }

  clearPassword= () => { this.props.updatePassword("")  }

  renderError() {
    const { error } = this.props;

    if(error) {
      return <IonToast
        isOpen={true}
        message={error}
        duration={4000}
      />
    }
  }


  render() {

        return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Login</IonTitle>

            <ion-buttons slot="primary">
              <ion-button onClick={this.props.clickRegister}>Register</ion-button>
            </ion-buttons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">

          {this.renderError()}
          <form>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                    type="email"
                    placeholder="Your email"
                    value={this.props.username || ""}
                    onIonChange={this.changeUsername}
                    required
                    clearInput
                    />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                    type="password"
                    placeholder="password"
                    value={this.props.password|| ""}
                    onIonChange={this.changePassword}
                    required
                    clearInput
                    />
            </IonItem>          
           </IonList>
           </form>

           <div className="ion-padding">
             <ion-button expand="block" type="submit" class="ion-no-margin" disabled={this.props.submitting} onClick={this.props.submitLogin}>{this.props.submitting ? "Submitting.." : "Login"}</ion-button>
           </div>
        </IonContent>
      </IonPage>
    );
/*    return  <Page name="login">
              <Navbar title="Login">
                <NavRight>
                <Link href="#" onClick={this.props.clickRegister}>Register</Link>
                </NavRight>
              </Navbar>

              {this.renderError()}


              <List inset form>
                <ListItem>
                  Enter your email and password
                </ListItem>
                <ListInput
                  label="Email"
                  type="text"
                  placeholder="Your email"
                  value={this.props.username || ""}
                  onChange={this.changeUsername}
                  onInputClear={this.clearUsername}
                  clearButton
                  >
              </ListInput>
              <ListInput
                  label="Password"
                  type="password"
                  placeholder="Your password"
                  clearButton
                  value={this.props.password || ""}
                  onChange={this.changePassword}
                  onInputClear={this.clearPassword}
                  >
              </ListInput>
              <ListItem><Button disabled={this.props.submitting} onClick={this.props.submitLogin} className='button-fill'>{this.props.submitting ? "Submitting.." : "Login"}</Button></ListItem>
            </List>
            </Page>*/
  }
}

export default LoginPage;