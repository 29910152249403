import React, { useState } from 'react'

import { map } from "lodash"
import { Journal } from "../declarations"

import { PickerColumn } from "@ionic/core";

import { 
  IonItem,
  IonLabel,
  IonPicker
} from '@ionic/react';



interface Props {
  times: any[];
  selected: number;
  label: string;
  setSelected: Function;
}

function TimePicker(props:Props) {

  const { label, times, selected, setSelected } = props;

  const TimeColumn  = {
    name: "Time",
    options: times.map((t) => { return { text: t[0], value: t[1] }})
  } as PickerColumn;;

  const [open, setOpen] = useState(false);

  return <React.Fragment>
     <IonItem>
      <IonLabel>{label}</IonLabel>
        {selected? (
          <IonLabel slot="end" onClick={() => { setOpen(true); }}>
            {selected}
          </IonLabel>
        ) : (
          <IonLabel className="placeHolder" slot="end" onClick={() => { setOpen(true); }}>Select a time</IonLabel>
        )}
       <IonPicker
         isOpen={open}
         columns={[TimeColumn]}
         buttons={[
           {
             text: "Cancel",
             role: "cancel",
             handler: value => {
               setOpen(false)
             }
           },
           {
             text: "Confirm",
             handler: value => {
               setSelected(value!)
             }
           }
           ]}
           ></IonPicker>
       </IonItem>
    </React.Fragment>
}

export default TimePicker;


