import { FCM } from "capacitor-fcm";

import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/messaging";

import { isPlatform } from "@ionic/react"

import { Plugins } from "@capacitor/core";
const { PushNotifications } = Plugins;

declare var window: any;

class Notifications {

  static register() {
    if(isPlatform("cordova")) {
      return Notifications.registerCordova()
    } else {
      return Notifications.registerFirebase()
    }
  }

  static registerCordova() {
      const fcm = new FCM();

      return new Promise((resolve, reject) => {
        PushNotifications.register().then(() => {
          return fcm.getToken().then((r) => {
            resolve(r.token)
           }).catch((err:any) => {
             reject(err.toString())
           })
         }).catch((err:any) => {
           reject(err.toString())
         })
      });
   }

   static registerFirebase() {

      return new Promise((resolve, reject) => {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            if(!window.messaging) {
                reject("Unable to turn on notifications")
                return;
            }
            window.messaging.getToken().then((currentToken:string) => {
              if (currentToken) {
                resolve(currentToken)
              } else {
                reject("Unable to turn on notifications")
              }
            }).catch((err:any) => {
              reject("Unable to turn on notifications")
            });
          }
       });
     });

   }

   static onRefresh(callback,error) {
     if(window.messaging) { 
       window.messaging.onTokenRefresh(() => {
         window.messaging.getToken().then((refreshedToken:string) => {
           callback(refreshedToken)
        }).catch((err:string) => {
          console.log('Unable to retrieve refreshed token ', err);
          error(err);
        });
      });
     }
   }

   static setup() {
     if(!isPlatform("cordova")) { 
        const firebaseConfig = {
         apiKey: "AIzaSyAWbFVts1EEpXu5QJtayIkaUOXXKOhXFS8", 
         authDomain: "sumday-5c93d.firebaseapp.com", 
         databaseURL: "https://sumday-5c93d.firebaseio.com", 
         projectId: "sumday-5c93d", 
         storageBucket: "", 
         messagingSenderId: "1082999603512", 
         appId: "1:1082999603512:web:86e5636b930187fa5681cd" }

        firebase.initializeApp(firebaseConfig);

        try {
          // Retrieve Firebase Messaging object.
          window.messaging = firebase.messaging();
          window.messaging.usePublicVapidKey("BB7gD5b7ePesF6at2miNB8MkwaK9BbPJfyWFe-faqebBOeF7qTFJzgINK_ApPLfPirpqoETeYFr4PopyrrBnrKc")
        } catch(e) { console.log(e) }
      }
   }

}

export default Notifications;