import { connect } from 'react-redux'

import MeasuresEdit from "./MeasuresEdit"

import { addMeasure, editMeasures, updateMeasure, saveMeasures, reorderMeasures, enterMeasure, deleteMeasure } from '../actions'

import { filter } from "lodash"


const mapStateToProps = (state:any, ownProps:any) => {
  return {
     measures: filter(state.sumday.measures || [], (measure) => !measure._delete),
     sampleMeasures: (state.sumday.index[0] || { measures: {} }).measures
   }
}

const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  return { 
    saveMeasures: () => {   dispatch(saveMeasures());  ownProps.history.goBack() },
    addMeasure: () => { dispatch(addMeasure()) },
    reorderMeasures: (from:number, to:number) => { dispatch(reorderMeasures(from, to)) },
    updateMeasure: (id:number, prop:string, value:any) => { dispatch(updateMeasure(id, prop, value))},
    editMeasures: () => { dispatch(editMeasures()) },
    enterMeasure: (measureId:number, value:string) => { dispatch(enterMeasure(0, measureId, value ))},
    deleteMeasure: (measureId:number) => { dispatch(deleteMeasure(measureId)) }
  };
}

const MeasuresEditContainer  = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasuresEdit)

export default MeasuresEditContainer