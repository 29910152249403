import { connect } from 'react-redux'

import SumDayApp from "./SumDayApp"

import { logout } from "../actions"

const mapStateToProps = (state:any, ownProps:any) => {
  return {
    index: state.sumday.index,
    loggedIn: state.sumday.loggedIn,
    verifying: state.sumday.token && !state.sumday.loggedIn
  }
}

const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  return { 
    logout: () => { dispatch(logout()); } 
  }
}

const SumDayAppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SumDayApp)

export default SumDayAppContainer;