import { connect } from 'react-redux'

import JournalEdit  from "./JournalEdit"

import { updateJournal, loadJournal, saveJournal, addJournalImage, enterMeasure, extractJournalId } from '../actions'

const mapStateToProps = (state:any, ownProps:any) => {
  return {
    journal: state.sumday.index[extractJournalId(ownProps)],
    measures: state.sumday.activeMeasures
   }
}

const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  let journalId = extractJournalId(ownProps);

  return { 
    loadJournal: () => { dispatch(loadJournal(journalId)) },
    updateJournal: (attr:any) => { dispatch(updateJournal(journalId,{ ...attr, dirty: true })) },
    saveJournal: () => { ownProps.history.goBack(); dispatch(saveJournal(journalId));   },
    addImage: (files:any) => { dispatch(addJournalImage(journalId, files))},
    enterMeasure: (measureId:number, value:any) => { dispatch(enterMeasure(journalId, measureId, value ))}
  };
}

const JournalEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalEdit)

export default JournalEditContainer;