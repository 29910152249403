import { connect } from 'react-redux'

import RegisterPage from "./RegisterPage"

import { updateLogin, submitRegistration } from '../actions'


const mapStateToProps = (state:any, ownProps:any) => {
  return { ...state.sumday.login }
}

const mapDispatchToProps = (dispatch:any, ownProps:any) => {
  return { 
    updateUsername: (txt) => { dispatch(updateLogin('username',txt)) },
    updatePassword: (txt) => { dispatch(updateLogin('password',txt)) },
    updateTOS: (checked) => { dispatch(updateLogin('tos',checked || false)) },
    updatePasswordConfirmation: (txt) => { dispatch(updateLogin('password_confirmation',txt)) },
    submitRegistration: () => { dispatch(submitRegistration()).then( () => ownProps.history.replace("/")) },
    clickTOS: () => { ownProps.history.push("/tos") },
    clickLogin: () => { ownProps.history.goBack() }
  };
}

const RegisterPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage)

export default RegisterPageContainer;