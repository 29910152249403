import React from 'react'

import { star, starOutline } from 'ionicons/icons';


import { 
  IonInput, 
  IonIcon, 
  IonList, 
  IonItem,
  IonCheckbox,
  IonLabel
} from '@ionic/react';


import { map } from  "lodash"

class MeasureInputs extends React.Component {


  renderMeasureInput = (measure) => {
    const name = `${measure.measure_type}Input`
    return this[name](measure)
  }

  checkboxInput = (measure) => {
    return <IonItem  key={measure.id}>
      <IonLabel>{measure.name}</IonLabel>
      <IonCheckbox slot="end" checked={this.value(measure) === '1'} onClick={(e) => this.checkboxChange(measure,e) } />
    </IonItem>
  }

  checkboxChange = (measure, e) => {
    this.props.onChange(measure.id, e.currentTarget.checked ? '1' : '0')
  }

  starsInput = (measure) => {
    return <IonItem  slot="end" key={measure.id}>
      <IonLabel>{measure.name}</IonLabel>
      <div className='stars_measure'>
        {map([1,2,3,4,5],(val) => this.renderStar(val, measure)  )}
      </div>
    </IonItem>
  }

  renderStar = (idx, measure) => {
    var val = this.value(measure)
    const ic  = (val >= idx) ? star : starOutline;

    return  <IonIcon key={idx} size="large" onClick={(e) => this.starsChange(measure, idx) } icon={ic} />
  }

  starsChange = (measure, value) => {
    this.props.onChange(measure.id, value)
  }

  fieldChange = (measure, e) => {
    this.props.onChange(measure.id, e.currentTarget.value)
  }

  numberInput = (measure) => {
    return <IonItem key={measure.id}>
      <IonLabel>{measure.name}</IonLabel>
      <IonInput 
        type='number' 
        className="align-right"
        value={this.value(measure)} 
        placeholder="NNNN"
        onIonChange={(e) => this.fieldChange(measure,e) }
      />
    </IonItem>
  }

  textInput = (measure) => {
    return <IonItem key={measure.id}>
      <IonLabel>{measure.name}</IonLabel>
      <IonInput 
         type='text' 
         className="align-right"
         text-right
         value={this.value(measure)} 
         placeholder={`enter ${measure.name}`}
         onIonChange={(e) => this.fieldChange(measure,e)}
         />

    </IonItem>
  }


  value = (measure) => {
    return (this.props.values || {})[measure.id] || ''
  }


  render() {
    return <IonList>
    {map(this.props.measures, this.renderMeasureInput)}
    </IonList>
  }
}


export default MeasureInputs