import React from 'react'

import LoginPageContainer from "./LoginPageContainer"
import AppMainContainer from "./AppMainContainer"
import JournalViewContainer from "./JournalViewContainer"
import JournalEditContainer from "./JournalEditContainer"
import MeasuresEditContainer from "./MeasuresEditContainer"
import AccountSettingsContainer from "./AccountSettingsContainer"
import RegisterPageContainer from "./RegisterPageContainer"
import TOSPage from "./TOSPage"

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


import { Route, Redirect, Switch } from 'react-router-dom';


import { AppPage } from '../declarations';

import Menu from '../components/Menu';
import { home, list } from 'ionicons/icons';

import { 
   IonContent, 
   IonPage, 
 } from '@ionic/react';

import { Journal } from "../declarations"


const appPages: AppPage[] = [
  {
    title: 'Journals',
    url: '/',
    icon: home
  },

  {
    title: 'Measures',
    url: '/measures/edit',
    icon: list
  },

  { 
    title: "Account Settings",
    url: "/settings",
    icon: list
  }
];

interface Props {
  loggedIn: boolean;
  verifying: boolean;
  history: any;

  index: { [key: string]: Journal; }
  logout: Function;
}

class SumDayApp extends React.Component<Props, any> {


  mainRoutes() {
    return <IonSplitPane contentId="main">
              <Menu appPages={appPages} logout={this.props.logout} />
              <IonRouterOutlet id="main">
                <Route exact={true} path="/"  component={AppMainContainer} />
              { /*<Route path="/register" component={RegisterPageContainer} exact={true} /> */}

                <Route exact={true} path="/edit/:journalId" component={JournalEditContainer} />
                <Route exact={true} path="/journals/:journalId" component={JournalViewContainer}   />
              
            <Route path="/measures/edit" component={MeasuresEditContainer} exact={true} />
            <Route path="/settings" component={AccountSettingsContainer} exact={true} />

              <Redirect exact={true} path="/login" to={{pathname: "/" }} />
            </IonRouterOutlet>
          </IonSplitPane>
  }

  loginRoutes() {
    return <Switch>
              <Route exact={true} path="/login" component={LoginPageContainer} />
              <Route exact={true} path="/register" component={RegisterPageContainer} />
              <Route path="/tos" component={TOSPage} exact={true} /> 
              <Redirect path="/" to={{pathname: "/login" }} />
            </Switch>
  }

  render() {
    let { loggedIn, verifying } = this.props;

    return (
       <IonApp>
        <IonReactRouter>
           { loggedIn && !verifying ? this.mainRoutes() : this.loginRoutes() }
        </IonReactRouter>
      </IonApp>
     )

  }

  renderLoggingIn() {
    return <IonPage>
      <IonContent>
         Logging In...
      </IonContent>
      </IonPage>
  }
}



export default SumDayApp;