import React, { useEffect, useRef } from 'react'

import TextareaAutosize from 'react-textarea-autosize';

import JournalListContainer from "../components/JournalListContainer"

import JournalDisplay from "../helpers/journal_display"

import MeasureInputs from "../components/MeasureInputs"

import { Journal } from "../declarations"

import { chevronBack } from 'ionicons/icons';


import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonList,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  useIonViewDidEnter
} from '@ionic/react';


interface Props {
  journalId: number;
  journal: Journal;

  measures: any[];

  history: any;

  enterMeasure: Function;
  loadJournal: Function;
  updateJournal: Function;
  addImage: Function;
  saveJournal: Function;
}


function renderImage(journal:Journal) {
  if(journal.image_url) {
    return <IonItem><div className='image_max_height_wrapper'><img alt="Journal" src={journal.image_url.medium_url} /></div></IonItem>

  }
}

function JournalEdit(props:Props) {

  const { 
    journal, 
    measures,
    journalId, 
    loadJournal, 
    updateJournal,
    enterMeasure,
    addImage,
    saveJournal
  } = props;

  const uploadRef = useRef<HTMLInputElement>(null);
  const textareaRef= useRef<HTMLInputElement>(null);


  useEffect(() => {
    loadJournal(journalId)
  }, [journalId, loadJournal])

  useIonViewDidEnter(() => {
    if(textareaRef  && textareaRef.current) {
      const t = textareaRef.current; 
      t.focus()
      t.select(); 
      t.selectionStart = t.selectionEnd; 
    }
    console.log('ionViewDidEnter event fired');
  });

  const clickUpload = (e:any) => {
    e.stopPropagation()
    e.preventDefault()
    if(uploadRef && uploadRef.current) {
      uploadRef.current!.click();
    }
  }

  const addFile = (e:any) => {
    const files = Array.from(e.target.files)  
    addImage(files)
  }

  const display = new JournalDisplay(journal)

  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => props.history.goBack() }><IonIcon icon={chevronBack}/></IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={(e) => saveJournal()}>Save</IonButton>
          </IonButtons>
          <IonTitle>{display.navbarTitle()}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
          <IonList>
              {renderImage(journal)}
              <IonItem>
                <TextareaAutosize 
                  inputRef={textareaRef}
                  className="journal-edit"
                  placeholder={display.prompt()}
                  value={journal.body||""}
                  onChange={(e:any) => updateJournal({ body: e.target.value} ) }
                />
               </IonItem>
                { display.journal.period === 'day' && !display.isPlan() &&
                  <IonItem>
                  <div className='upload-button'>
                    <div className='editor__upload'>
                      <IonButton onClick={clickUpload}>+ Add a Picture</IonButton>
                      <input type='file' id="upload-file" className='invisible_file_input' onChange={addFile} ref={uploadRef} />  

                    </div>
                  </div>
                  </IonItem>
                }
            { display.journal.period === 'day' && !display.isPlan() && <MeasureInputs measures={measures} values={journal.measures || {}} onChange={enterMeasure} /> }
          </IonList>
        <JournalListContainer header="Related" journals={journal.references} history={props.history} />
        <JournalListContainer header="Children" journals={journal.children} history={props.history} />
      </IonContent>
     </IonPage>
}

export default JournalEdit;