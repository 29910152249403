import { connect } from 'react-redux'

import AccountSettings from "./AccountSettings"

import { updateNotifications } from '../actions'


const mapStateToProps = (state, ownProps) => {
  return {
    device_token: state.sumday.device_token,
    notifications: state.sumday.notifications,
    settings: state.sumday.settings,
    account: state.sumday.account
   }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { 
    updateNotifications: (on) => { dispatch(updateNotifications(on)) },
    updateAccountSettings: (props) => { console.log(props) },
    saveAccountSettings: () => { console.log("Save");  ownProps.history.goBack() }
  };
}

const AccountSettingsContainer  = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings)

export default AccountSettingsContainer