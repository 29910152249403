import React from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


import './theme/application.scss'

import crypto from 'crypto'


import { isPlatform } from "@ionic/react"


import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import sumDayReducer from "./reducer";
import { Provider } from "react-redux";
import { verifyLogin } from './actions'



import SumDayAppContainer from './pages/SumDayAppContainer'

/*import RegisterPageContainer from './components/RegisterPageContainer'
import JournalEditContainer from './components/JournalEditContainer'
import JournalViewContainer from './components/JournalViewContainer'
import MeasuresEditContainer from './components/MeasuresEditContainer'
import MenuPanelContainer from './components/MenuPanelContainer'
import AccountSettingsContainer from './components/AccountSettingsContainer'
*/


class App extends React.Component {

  store: any

  constructor(props:object) {
    super(props);

    if(!localStorage['device']) {
      localStorage['device'] = crypto.randomBytes(64).toString('hex');
    }

    // Initialize redux store
    let store = createStore(
      combineReducers({
        sumday: sumDayReducer
      }),
      this.baseStore(),
      composeWithDevTools(applyMiddleware(thunkMiddleware))
    );

    this.store = store;
  }

  componentDidMount() {
    this.store.dispatch(verifyLogin());
  }

  baseStore() {
    var token = localStorage['token'] && localStorage['token'] !== "null" ? localStorage["token"] : null;
    return {
      sumday: {
        isApp: isPlatform("cordova"),
        isWeb: !isPlatform("cordova"),
        login: { username: "", password: "" },
        token: token,
        username: localStorage['username'],
        journals: [],
        index: {},
        notifications: {
          on: localStorage['notificationsOn'] === 'on'
        },
        device: localStorage['device']
      }
    }
  }

  render() {
    return <Provider store={this.store}>
      <SumDayAppContainer />
     </Provider>
  }

}

export default App;
