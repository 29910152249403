import { connect } from 'react-redux'

import JournalView from "./JournalView"

import { loadJournal, extractJournalId } from '../actions'


const mapStateToProps = (state:any, ownProps:any) => {
  return {
    journalId: extractJournalId(ownProps),
    journal: state.sumday.index[extractJournalId(ownProps)],
    measures: state.sumday.activeMeasures
   }
}

const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  return { 
    loadJournal: () => { dispatch(loadJournal(extractJournalId(ownProps))) },
    editJournal: () => { 
      ownProps.history.push(`/edit/${ownProps.match.params.journalId}`)
    }
  };
}

const JournalViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalView)

export default JournalViewContainer;