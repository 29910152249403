import React from 'react'


import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonButtons,
   IonButton,
   IonIcon,
   IonList,
   IonLabel,
   IonItem,
   IonInput,
   IonRouterLink,
   IonCheckbox
 } from '@ionic/react';

 import { chevronBack } from 'ionicons/icons';



interface Props {
  username: string;
  password: string;
  password_confirmation: string;
  tos: boolean;
  register_error: string;
  submitting: boolean;

  updateUsername: Function;
  updatePassword: Function;
  updateTOS: Function;
  updatePasswordConfirmation: Function;
  submitRegistration: Function;
  clickTOS: Function;
  clickLogin: Function;

}



class RegisterPage extends React.Component<Props,any> {

  changeUsername = (e:any) => {
    this.props.updateUsername(e.target.value)
  }

  changePassword = (e:any) => {
    this.props.updatePassword(e.target.value)
  }

  changePasswordConfirmation = (e:any) => {
    this.props.updatePasswordConfirmation(e.target.value)
  }

  renderError() {
    const { register_error } = this.props;

    if(register_error) {
       return <IonItem color="danger">
          <IonLabel>{register_error}</IonLabel>
        </IonItem>
    }
  }

  updateTOS = (e:any) => {
    this.props.updateTOS(e.currentTarget.checked)
  }

  render() {
    return  <IonPage>
       <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonButton onClick={() => this.props.clickLogin() }><IonIcon icon={chevronBack}/></IonButton>
          </IonButtons>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
       </IonHeader>
       <IonContent>
         <IonList>
           {this.renderError()}
           <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                type="email"
                placeholder="Your email"
                value={this.props.username || ""}
                onIonChange={this.changeUsername}
                required
                clearInput
                />
           </IonItem>
           <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                placeholder="Choose a password"
                value={this.props.password || ""}
                onIonChange={this.changePassword}
                required
                clearInput
                />
           </IonItem>
           <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                placeholder="Re-enter password"
                value={this.props.password_confirmation || ""}
                onIonChange={this.changePasswordConfirmation}
                required
                clearInput
                />
           </IonItem>
           <IonItem>
             <IonLabel>I accept the terms of use
             </IonLabel>
             <IonCheckbox 
                 checked={this.props.tos || false} 
                 onClick={this.updateTOS} 
                 />
           </IonItem>
         </IonList>
         <IonLabel>
           <div className="ion-text-center">
             <IonRouterLink routerLink="/tos">view terms of use</IonRouterLink>
           </div>
         </IonLabel>

          <div className="ion-padding">
             <IonButton expand="block" type="submit" class="ion-no-margin" disabled={this.props.submitting} onClick={(e:any) => this.props.submitRegistration()} className='button-fill'>{this.props.submitting ? "Submitting.." : "Register"}</IonButton>
           </div>
        </IonContent>
       </IonPage>;
  }

 
}

export default RegisterPage;