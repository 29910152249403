import { connect } from 'react-redux'

import AppMain from "./AppMain"

import { loadJournals } from '../actions'

const mapStateToProps = (state:any, ownProps:any) => {
  return {
    journals: state.sumday.journals
  }
}

//map(state.journals || [], (jid) => state.index[jid] )
const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  return {
    'loadJournals': () => { return dispatch(loadJournals()) },
    'editMeasures': () => { 
      //dispatch(navigateTo("/measures/edit"))
    },
    'openPanel': () => { 
      //dispatch(navigateTo("/menu-panel"))
    }
   }
}

const AppMainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppMain)

export default AppMainContainer;