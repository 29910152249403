import { each } from 'lodash'

import { FCM } from '@ionic-native/fcm';
 
class SumDayApi {


  static token() {
    return window.localStorage['token']
  }


 static objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '[' + key + ']');
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root){
        return Array.isArray(ignoreList)
            && ignoreList.some(function(x) { return x === root; });
    }

    appendFormData(obj, rootName);
    return formData;
 }


  static login(username, device, password) {
    let body = { user: { username, password}, device: device }
    return SumDayApi.sendData("/api/users/login",body,'POST')
  }

  static register(props) {
    let body = { user: props }
    return SumDayApi.sendData("/api/users",body,'POST')
  }


  static verify(token,device) {
    return window.fetch(SumDayApi.formatUrl(`/api/users?api_key=${token}&device=${device}`)) 
    .then((response) => {
      if(response.ok) {
        return response.json();
      } else {
        return { error: 'Invalid Token' };
      }
    })
  }

  static journals() {
    return SumDayApi.get(`/api/journals`)
  }

  static ignoreJournal(jid) {
    return SumDayApi.get(
      `/api/journals/${jid}`,"DELETE");
  }  

  static journal(jid) {
    return SumDayApi.get(`/api/journals/${jid}`)
  }

  static syncNotificationToken(token, device) {
    let params = {
      token: token,
      device: device
    }

    return SumDayApi.sendData(
      "/api/notification_token",
      params,
      'POST').then( (json) => {
      return {...json }
    }) 
  }

  static fetchSettings() {
    return SumDayApi.get(`/api/settings`)
  }

  static saveJournal(journal) {
    let params = {
      body: journal.body,
      measures: journal.measures
    }
    if(journal.image) { 
      params.image = journal.image 
    }
    return SumDayApi.sendData(
      `/api/journals/${journal.id}`,
      { journal: params },
      'PUT').then( (json) => {
      return {...json, image: null }
    }) 

  }

  static getMeasures() {
    return SumDayApi.get(`/api/measures`)
  }

  static saveMeasures(measures) {
    var measureHash = {}
    each(measures, (measure,idx) => {
      measureHash[idx] = measure
    })

    let params = {
      measures: measureHash
    }
    return SumDayApi.sendData(
      `/api/measures/sync`,
      params,
      'POST')
  }

  static formatUrl(url) {
    //return `http://localhost:3000${url}`
    return `https://www.sumday.app${url}`
  }

  static get(url, method= 'GET') {
    return window.fetch(`${SumDayApi.formatUrl(url)}?api_key=${SumDayApi.token()}`, {
      method: method
    })
     .then(function(response) { 
      return response.json()
    }) 
  }

  static sendData(url,params,method) {
    let serverParms = { ...params, api_key: SumDayApi.token() }
    return window.fetch(
      SumDayApi.formatUrl(url), {
        method: method,
        body: SumDayApi.objectToFormData(serverParms)
      })
    .then( (response) => {
      return response.json();
    })     
  }


}

export default SumDayApi;