import React from 'react'

import TimePicker from "../components/TimePicker"

import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonButtons,
   IonButton,
   IonCheckbox,
   IonList,
   IonLabel,
   IonItem,
   IonMenuButton,
   IonSelect,
   IonSelectOption,
   IonDatetime
 } from '@ionic/react';



interface Props {
  notifications: any;
  account: any;
  updateNotifications: Function;
  fetchSettings: Function;
  saveAccount: Function;
}


class AccountSettings extends React.Component<Props, any>  {

  state: any;

  constructor(props:Props) {
    super(props);

    this.state = {
      notificationPicker: false
    }

  }

  componentDidMount = () => {
    //this.props.fetchSettings();
  }

  updateNotifications = (e) => {
     this.props.updateNotifications(e.currentTarget.checked)
  }

  setSelectedDate = (tm) => {
    console.log(tm)
  }

  renderNotificationSettings() {
    let { times_zones, times, journal_notification_time, planning_notification_time } = this.props.account;


    let selectedDate = "";

    return <React.Fragment>
       <TimePicker times={times} selected={journal_notification_time} label="Notification Time" setSelected={(val) => console.log(val)} />
    </React.Fragment>
  }

  render() {
    let { saveAccount } = this.props;
    
    return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => saveAccount()} >Save</IonButton>
          </IonButtons>
          <IonTitle>Account Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
       <IonContent>
         <IonList>
           <IonItem>
             <IonLabel>Enable Notifications</IonLabel>
             <IonCheckbox slot="end" checked={this.props.notifications.on || false} onClick={this.updateNotifications} />
           </IonItem>
           {this.props.notifications.on && this.renderNotificationSettings()}
          </IonList>
       </IonContent>
    </IonPage>
  }
}


export default AccountSettings