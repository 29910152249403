import React from 'react'

import { 
   IonContent, 
   IonHeader, 
   IonPage, 
   IonTitle, 
   IonToolbar,
   IonButtons,
   IonButton,
   IonIcon,
   IonText
 } from '@ionic/react';

 import { chevronBack } from 'ionicons/icons';


function TOSPage(props:any) {
    return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonButton onClick={() => props.history.goBack() }><IonIcon icon={chevronBack}/></IonButton>
          </IonButtons>
          <IonTitle>Terms of Service</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonText>
          <p>SumDay is offered as a free service to it's members.  We do not sell your data.</p>
          <p>SumDay is not responsible for service disruptions or lost data.</p>
        </IonText>
      </IonContent>
     </IonPage>;
}

export default TOSPage;