import React from 'react'

import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react';

import { RefresherEventDetail } from '@ionic/core';


//import JournalDisplay from "../helpers/journal_display"

import JournalListContainer from "../components/JournalListContainer"


interface Props {
  journals: number[];

  history: any;
  
  loadJournals: Function;
}


class SumDayApp extends React.Component<Props, any>  {

  componentDidMount() {
    // Force a refresh of journals on load
    document.addEventListener("resume", this.journalLoad, false)
    this.props.loadJournals();
  }

  componentWillUnmount() {
    document.removeEventListener("resume", this.journalLoad);
  }

  journalLoad = (e:any) => {
    this.props.loadJournals();
  }

  refetchJournals = (event: CustomEvent<RefresherEventDetail>) => {

    this.props.loadJournals().then(() => {
      event.detail.complete();
    })

  }

  render() {
    let { journals } = this.props;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>SumDay</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonRefresher slot="fixed" onIonRefresh={this.refetchJournals}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <JournalListContainer journals={journals} history={this.props.history} />
        </IonContent>
      </IonPage>
    );
  }
}

export default SumDayApp;