import React from 'react'

import JournalDisplay from "../helpers/journal_display"
import MeasuresDisplay from "./MeasuresDisplay.jsx"

import { map } from "lodash"
import { Journal } from "../declarations"


import { 
  IonItem,
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption
} from '@ionic/react';


const nl2br = require('react-nl2br');



interface Props {
  journals: Journal[];
  measures: any[];
  noEdit: boolean;
  header: string;

  editJournal: Function;
  ignoreJournal: Function;
  loadJournal: Function;
  visitJournal: Function;
  visitJournalEdit: Function;
}


class JournalList extends React.Component<Props,any> {

  renderImage(display:JournalDisplay) {
    const journal = display.journal;
    if(journal.image_url) {
      return <div className='image_max_height_wrapper'><img alt="" src={journal.image_url.medium_url} /></div>
    }
  }


 renderContent = (display:JournalDisplay) => {
   return <React.Fragment>
   {this.renderImage(display)}
   { display.pending() ? <p>{nl2br(display.body())}</p> :  <h2>{nl2br(display.body())}</h2> }
   </React.Fragment>
  }

renderMeasures = (display:JournalDisplay) => {
  if(!display.pending()) { 
    return <MeasuresDisplay values={display.measures} measures={this.props.measures} />
  }
}

editJournal(id:number) {
  this.props.visitJournalEdit(id)
}

viewJournal(id:number  ) {
  this.props.visitJournal(id)
}

renderRow = (journal:Journal, idx:number) => {

    const display = new JournalDisplay(journal);

    const detailClass = display.isPlan() ? "--plan" : "--journal"
    const enteredClass = display.pending() ? '' : '-entered'

    const planTag = <div className='date-details__tag'>plan</div>;

    return <IonItemSliding key={journal.id} >
      <IonItem onClick={() => { display.pending() || display.day() ? this.editJournal(journal.id) : this.viewJournal(journal.id) } } >
        <IonLabel className="date-details" slot="start">
          <div className={`date-details__block date-details__block${detailClass}${enteredClass} ion-text-center`}>
            <div className='date-details__line1'>{display.dateLine(1)}</div>
            <div className='date-details__line2'>{display.dateLine(2)}</div>
            <div className='date-details__line3'>{display.dateLine(3)}</div>
            {display.isPlan() && planTag}
          </div>
        </IonLabel>

        <IonLabel className="journal-details">
         {this.renderContent(display)}
         {this.renderMeasures(display)}
        </IonLabel>
      </IonItem>
      {!this.props.noEdit && display.pending() &&
      <IonItemOptions>
        { display.day() && <IonItemOption  color="danger" onClick={() => this.props.ignoreJournal(display.id) }>Ignore</IonItemOption> }
      </IonItemOptions>
     }
     </IonItemSliding>
  }

  render() {
    return <React.Fragment>
      <IonList>
      {this.props.journals.length > 0 && 
       this.props.header &&          
       <IonListHeader>
         <IonLabel>{this.props.header}</IonLabel>
       </IonListHeader>
      }
      {
      map(this.props.journals, this.renderRow) 
      }
      </IonList>
    </React.Fragment>
  }
}

export default JournalList;