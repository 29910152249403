import React from 'react'

import JournalDisplay from "../helpers/journal_display"
import MeasuresDisplay from "./MeasuresDisplay.jsx"

import { Journal } from "../declarations"

import { 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent
} from '@ionic/react';


const nl2br = require('react-nl2br');



interface Props {
  journal: Journal;
  measures: any[];
}


class JournalEntryView extends React.Component<Props,any> {

  renderImage(display:JournalDisplay) {
    const journal = display.journal;
    if(journal.image_url) {
      return <div className='image_max_height_wrapper'><img alt="" src={journal.image_url.medium_url} /></div>
    }
  }

 renderContent = (display:JournalDisplay) => {
   return <React.Fragment>
   {this.renderImage(display)}
   <p>{nl2br(display.body())}</p>
   </React.Fragment>
  }

  renderMeasures = (display:JournalDisplay) => {
    if(!display.pending()) { 
      return <MeasuresDisplay values={display.measures} measures={this.props.measures} />
    }
  }

  render() {
    const {journal } = this.props;
    const display = new JournalDisplay(journal);

    return <IonCard key={journal.id}>
      <IonCardHeader>
        <IonCardSubtitle>{display.period().toUpperCase()}</IonCardSubtitle>
        <IonCardTitle>{display.title()}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {this.renderContent(display)}
      </IonCardContent>
      {this.renderMeasures(display)}
    </IonCard>

  }
}

export default JournalEntryView;