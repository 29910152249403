import { connect } from 'react-redux'

import LoginPage from "./LoginPage.jsx"

import { updateLogin, submitLogin } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return { ...state.sumday.login }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { 
    updateUsername: (txt) => { dispatch(updateLogin('username',txt)) },
    updatePassword: (txt) => { dispatch(updateLogin('password',txt)) },
    submitLogin: () => { dispatch(submitLogin()) },
    clickRegister: () => { 
      ownProps.history.push("/register")
    }
  };
}

const LoginPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)

export default LoginPageContainer;