import { connect } from 'react-redux'

import JournalList from "./JournalList"

import { map } from "lodash"

import {  ignoreJournal, loadJournal } from '../actions'



const mapStateToProps = (state:any, ownProps:any) => {
  return {
    journals: map(ownProps.journals || [], (jid) => state.sumday.index[jid] ),
    measures: state.sumday.activeMeasures
  }
}

const mapDispatchToProps = (dispatch:Function, ownProps:any) => {
  return {
    ignoreJournal: (journalId:number) => { dispatch(ignoreJournal(journalId)) },

    loadJournal: (journalId:number) => { return dispatch(loadJournal(journalId)) },
    visitJournalEdit: (journalId:number) => {
      ownProps.history.push(`/edit/${journalId}`)
     },
    visitJournal: (journalId:number) => { 
      ownProps.history.push(`/journals/${journalId}`)
    }
   }
}

const JournalListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalList)

export default JournalListContainer;