
import dayjs from "dayjs"
import weekOfYear from 'dayjs/plugin/weekOfYear'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import { Dayjs } from 'dayjs';

import { Journal } from "../declarations"

dayjs.extend(weekOfYear)
dayjs.extend(quarterOfYear)

abstract class PeriodDisplay {

  journal: Journal;
  start: Dayjs;
  end: Dayjs;

  constructor(journal:Journal) {
    this.journal = journal
    this.start = dayjs(journal.start_on)
    this.end = dayjs(journal.end_on)
  }

  prompt() {
    if(this.journal.entry_type === "journal") {
      return `How'd the ${this.journal.period} go?`;
    } else {
      return `What's the plan for the ${this.journal.period}?`;
    }
  }

  placeholder() {
    return `Sum up the ${this.journal.period}`
  }

  abstract title(): string;
  abstract dateLine(line:number): string;

  navbarTitle() : string {
    return this.title();
  }

}

class DayDisplay extends PeriodDisplay {

  title() : string {
    return this.start.format("dddd M/D")
  }

  navbarTitle() : string {
    return this.start.format("ddd M/D")
  }



  dateLine(line:number): string {
    switch(line) {
      case 1: return this.start.format("ddd").toUpperCase();
      case 2: return this.start.format("D");
      case 3: 
      default:
        return this.start.format("MMM").toUpperCase();
    }
  }


}

class WeekDisplay extends PeriodDisplay {

  title() : string {
    return `Week of ${this.start.format("M/D")}`
  }

  dateLine(line:number): string {
    switch(line) {
      case 1: return "WEEK"
      case 2: return this.start.format("M/D")
      case 3: 
      default:
        return this.start.format("YYYY").toUpperCase();
    }
  }
}

class MonthDisplay extends PeriodDisplay {

  title() : string {
    return this.start.format("MMMM YYYY")
  }

  dateLine(line:number): string {
    switch(line) {
      case 1: return "MONTH"
      case 2: return this.start.format("MMM").toUpperCase();
      case 3: 
      default: 
        return this.start.format("YYYY").toUpperCase();
    }
  }
}

class QuarterDisplay extends PeriodDisplay {

  title() : string {
    return `Q${this.start.quarter()} ${this.start.format("YYYY")}`
  }

  dateLine(line:number): string {
    switch(line) {
      case 1: return "QUARTER"
      case 2: return `Q${this.start.quarter()}`
      case 3: 
      default: 
        return this.start.format("YYYY").toUpperCase();
    }
  }
}

class YearDisplay extends PeriodDisplay {

  title() : string {
    return this.start.format("YYYY");
  }

  dateLine(line:number): string {
    switch(line) {
      case 1: return "YEAR";
      case 2: return  this.start.format("YYYY").toUpperCase();
      case 3: 
      default: 
        return ""
    }
  }
}


class JournalDisplay {

  journal:Journal;
  id:number;
  measures:any;
  periodDisplay:PeriodDisplay;

  
  constructor(journal:Journal) {
    this.journal = journal
    this.id = journal.id
    this.measures = journal.measures
    this.periodDisplay = this.createPeriodDisplay(journal)
  }

  createPeriodDisplay(journal:Journal): PeriodDisplay {
    var periodDisplay:PeriodDisplay;

    switch(journal.period) {
      case 'day': periodDisplay = new DayDisplay(journal); break;
      case 'week': periodDisplay = new WeekDisplay(journal); break;
      case 'month': periodDisplay = new MonthDisplay(journal); break;
      case 'quarter': periodDisplay = new QuarterDisplay(journal); break;
      case 'year': 
      default: 
        periodDisplay = new YearDisplay(journal);
    }
    return periodDisplay;
  }


  body() {
    const { journal } = this;
    if(!journal.body) {
      return this.periodDisplay.prompt()
    } else if (journal.dirty) {
      return "(DRAFT) " + this.journal.body;
    } else {
      return this.journal.body;
    }
  }

  period() : string {
    return this.journal.period;
  }

  navbarTitle() : string {
    return `${this.isPlan() ? "Plan for" : "Journal for"} ${this.periodDisplay.navbarTitle()}`
  }


  title()  : string {
    return this.periodDisplay.title()
  }

  dateLine(line:number) : string {
    return this.periodDisplay.dateLine(line)
  }

  prompt()  : string {
    return this.periodDisplay.prompt()
  }

  day() : boolean {
    return this.journal.period === "day"
  }

  pending() : boolean {
    return this.journal.status === "pending"
  }

  isPlan() : boolean {
    return this.journal.entry_type === "plan"
  }
}

export default JournalDisplay;